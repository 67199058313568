import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SgHeadings from "../components/sg-headings"
import { BgImage } from "gbimage-bridge"
import { getImage } from "gatsby-plugin-image"
import Cta from "../components/call_to_action"
import BlocksRenderer from "../components/blocks-renderer"

export default function AboutPage ( { data } ) {
const seo = {
      metaTitle: "About Us",
      metaDescription: data.strapiGlobal.defaultSeo.metaDescription,
    }

const background = getImage(data.aboutBg)
const shortDesc = "Making tours and activites famous"
const headline = "Our Mission"
const blocks = data.strapiAbout.blocks


   
return (
        <Layout>
          <Seo seo={seo} />
          <div className="relative w-full h-full bg-gradient-to-r from-black to-cyan-700">
            <BgImage 
              image={background} 
              alt="A view of Finnish Northern Lights reflected off a lake" 
              className="absolute h-96 bg-center object-cover mix-blend-overlay" />
                <div className="container absolute top-0 left-1/2 -translate-x-1/2">
                  <SgHeadings title={headline} description={shortDesc} />
                </div>  
          </div>
            <div className="mt-4 space-y-4 pb-6">
              <BlocksRenderer blocks={blocks} /></div>
              <Cta />
        </Layout>
      )
    }

// query from Strapi

export const query = graphql`    
query AboutQuery {
  strapiAbout {
    blocks {
      ...Blocks
    }
  }
  strapiGlobal {
    defaultSeo {
      metaTitle
      metaDescription
    }
  }
  aboutBg: file(relativePath: {eq: "images/about.jpg"}) {
    childImageSharp {
      gatsbyImageData
    }
  }
}
`
